import type { SignupPurchaseVars } from '~/stores/signup';
import { loadScript } from '~/utils/importer';
import type { LooseObject } from '~/types/generic';

export const useTracking = () => {
  const fireRegistrationTracking = (vars: SignupPurchaseVars) => {
    if (vars.monthlyTotal > 0) {
      // Required fields
      window.afid = vars.uid; // unique order or sale ID
      window.afgoal = 'signup'; // goal name. Can be 'lead', 'sale', 'signup' or other
      window.afsecure = '2656f5661c57e17e2a54014996670d68'; // security hash provided by NZ Compare manager
      window.afoffer_id = 486; // Offer ID provided by NZ Compare manager
      window.afstatus = 2; // Default Conversion Status: 1 - "Approved", 2 - "Pending", 3 - "Declined", 5 - "Hold"

      // Optional fields
      // window.afprice = ''; // total plan price. Sale programs - required, lead programs - optional
      // window.afcomment = ''; // any comments for this order
      // window.custom_field1 = ''; // custom field 1
      // window.custom_field2 = ''; // custom field 2
      // window.custom_field3 = ''; // custom field 3
      // window.custom_field4 = ''; // custom field 4
      // window.custom_field5 = ''; // custom field 5
      // window.custom_field6 = ''; // custom field 6
      // window.custom_field7 = ''; // custom field 7

      loadScript('https://track.roeye.co.nz/track.js');
    }
  };

  const { proxy } = useScriptMetaPixel();

  const trackViewContent = (params: LooseObject) => {
    proxy.fbq('track', 'ViewContent', params);
    // console.log('[FBQ] VIEW CONTENT', params);
  };

  const trackPurchase = (vars: SignupPurchaseVars, params: LooseObject = {}) => {
    proxy.fbq('track', 'Purchase', {
      value: vars.monthlyTotal,
      currency: 'NZD',
      ...params,
    });
    // console.log('[FBQ] PURCHASE', vars);
  };

  const trackInitiateCheckout = (params: LooseObject) => {
    proxy.fbq('track', 'InitiateCheckout', params);
    // console.log('[FBQ] INITIATE CHECKOUT', params);
  };

  const trackContact = (params: LooseObject) => {
    proxy.fbq('track', 'Contact', params);
    // console.log('[FBQ] CONTACT', params);
  };

  const trackPaymentInfo = (params: LooseObject) => {
    proxy.fbq('track', 'PaymentInfo', params);
    // console.log('[FBQ] PAYMENT INFO', params);
  };

  const trackPageView = () => {
    proxy.fbq('track', 'PageView');
  };

  return {
    fireRegistrationTracking,
    trackViewContent,
    trackPurchase,
    trackInitiateCheckout,
    trackContact,
    trackPaymentInfo,
    trackPageView,
  };
};
