export const loadScript = (url: string) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.addEventListener('load', () => resolve(script), false);
    script.addEventListener('error', () => reject(script), false);
    document.body.appendChild(script);
  });
};

export const loadScripts = (urls: string[]) => {
  return Promise.all(urls.map(loadScript));
};
