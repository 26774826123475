import { default as _5_45month_45cart_45offeribjN87n7h5Meta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/5-month-cart-offer.vue?macro=true";
import { default as _6_45month_45half_45price8y20Dk5rTKMeta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/6-month-half-price.vue?macro=true";
import { default as abouteX7wpIBxekMeta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/about.vue?macro=true";
import { default as billingZJwqGpYwqmMeta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/account/billing.vue?macro=true";
import { default as indexi4Dk6L7lePMeta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/account/index.vue?macro=true";
import { default as profilewLSXCnHmNaMeta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/account/profile.vue?macro=true";
import { default as accountau7q1TUkpsMeta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/account.vue?macro=true";
import { default as authorizex8ov5Yatd0Meta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/authorize.vue?macro=true";
import { default as bbc_45promoZVHZWcaHjYMeta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/bbc-promo.vue?macro=true";
import { default as broadband_45friends_45and_45familyb1lQfxXF0qMeta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/broadband-friends-and-family.vue?macro=true";
import { default as bundleslJ5CuwLJv6Meta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/bundles.vue?macro=true";
import { default as chatsRa5fSrlXYMeta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/chat.vue?macro=true";
import { default as compass_45embedKIJ6zbwKhFMeta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/compass-embed.vue?macro=true";
import { default as compass9ni1YfkLa1Meta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/compass.vue?macro=true";
import { default as contactxx9RVSuwUgMeta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/contact.vue?macro=true";
import { default as coverageevJe5jlLWHMeta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/coverage.vue?macro=true";
import { default as glimpSJlYcCnTNVMeta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/glimp.vue?macro=true";
import { default as hyperfibrehYnB3HzuEfMeta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/hyperfibre.vue?macro=true";
import { default as index7QULYiK9AVMeta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/index.vue?macro=true";
import { default as internet_45speed_45testYt4JbH04a0Meta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/internet-speed-test.vue?macro=true";
import { default as join_45now208fEVKVquMeta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/join-now.vue?macro=true";
import { default as logina9z98nLKACMeta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/login.vue?macro=true";
import { default as logoutARfpX0Wm29Meta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/logout.vue?macro=true";
import { default as mobile_45compareg6R4L1nQNxMeta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/mobile-compare.vue?macro=true";
import { default as mobile_45friends_45and_45family1wMlocmNt9Meta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/mobile-friends-and-family.vue?macro=true";
import { default as indexsODkf854b2Meta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/mobile/index.vue?macro=true";
import { default as accountjPelNWerEvMeta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/mobile/register/account.vue?macro=true";
import { default as connection_45setupQQBKppM3iPMeta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/mobile/register/connection-setup.vue?macro=true";
import { default as index8o9QBaCi3lMeta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/mobile/register/index.vue?macro=true";
import { default as order_45summaryY3ODCoA0iWMeta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/mobile/register/order-summary.vue?macro=true";
import { default as select_45plancpqFIDIfmdMeta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/mobile/register/select-plan.vue?macro=true";
import { default as successg3zXbWAieHMeta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/mobile/register/success.vue?macro=true";
import { default as registerBhA7sDaS2OMeta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/mobile/register.vue?macro=true";
import { default as our_45plansd3X0BwBshXMeta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/our-plans.vue?macro=true";
import { default as plansCzbA89XpovMeta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/plans.vue?macro=true";
import { default as privacyFK1Y06mJ8gMeta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/privacy.vue?macro=true";
import { default as promotions3O9mjtZu6bMeta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/promotions.vue?macro=true";
import { default as refer_45a_45friendJ0wqn2jNVhMeta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/refer-a-friend.vue?macro=true";
import { default as _1dTwqkoOywoMeta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/register/1.vue?macro=true";
import { default as _2uFaKkAp6OmMeta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/register/2.vue?macro=true";
import { default as _3AX3cNiAuvUMeta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/register/3.vue?macro=true";
import { default as _4lmrKynrEkcMeta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/register/4.vue?macro=true";
import { default as _5lVyFMvr5cDMeta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/register/5.vue?macro=true";
import { default as indexl6IiLmy5cHMeta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/register/index.vue?macro=true";
import { default as successlvQDlweSU7Meta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/register/success.vue?macro=true";
import { default as registerve287vCXZMMeta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/register.vue?macro=true";
import { default as simply_45broadbandF2pFkpAu7pMeta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/simply-broadband.vue?macro=true";
import { default as studentcard_45exclusive_45offer41GNsfHRerMeta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/studentcard-exclusive-offer.vue?macro=true";
import { default as sustainabilityusEo9l6udhMeta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/sustainability.vue?macro=true";
import { default as termscQxhxEDPsrMeta } from "/codebuild/output/src2177761829/src/zeronet-web/pages/terms.vue?macro=true";
export default [
  {
    name: "5-month-cart-offer",
    path: "/5-month-cart-offer",
    meta: _5_45month_45cart_45offeribjN87n7h5Meta || {},
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/5-month-cart-offer.vue")
  },
  {
    name: "6-month-half-price",
    path: "/6-month-half-price",
    meta: _6_45month_45half_45price8y20Dk5rTKMeta || {},
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/6-month-half-price.vue")
  },
  {
    name: "about",
    path: "/about",
    meta: abouteX7wpIBxekMeta || {},
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/about.vue")
  },
  {
    name: accountau7q1TUkpsMeta?.name,
    path: "/account",
    meta: accountau7q1TUkpsMeta || {},
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/account.vue"),
    children: [
  {
    name: "account-billing",
    path: "billing",
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/account/billing.vue")
  },
  {
    name: "account",
    path: "",
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/account/index.vue")
  },
  {
    name: "account-profile",
    path: "profile",
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/account/profile.vue")
  }
]
  },
  {
    name: "authorize",
    path: "/authorize",
    meta: authorizex8ov5Yatd0Meta || {},
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/authorize.vue")
  },
  {
    name: "bbc-promo",
    path: "/bbc-promo",
    meta: bbc_45promoZVHZWcaHjYMeta || {},
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/bbc-promo.vue")
  },
  {
    name: "broadband-friends-and-family",
    path: "/broadband-friends-and-family",
    meta: broadband_45friends_45and_45familyb1lQfxXF0qMeta || {},
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/broadband-friends-and-family.vue")
  },
  {
    name: "bundles",
    path: "/bundles",
    meta: bundleslJ5CuwLJv6Meta || {},
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/bundles.vue")
  },
  {
    name: "chat",
    path: "/chat",
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/chat.vue")
  },
  {
    name: "compass-embed",
    path: "/compass-embed",
    meta: compass_45embedKIJ6zbwKhFMeta || {},
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/compass-embed.vue")
  },
  {
    name: "compass",
    path: "/compass",
    meta: compass9ni1YfkLa1Meta || {},
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/compass.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactxx9RVSuwUgMeta || {},
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/contact.vue")
  },
  {
    name: "coverage",
    path: "/coverage",
    meta: coverageevJe5jlLWHMeta || {},
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/coverage.vue")
  },
  {
    name: "glimp",
    path: "/glimp",
    meta: glimpSJlYcCnTNVMeta || {},
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/glimp.vue")
  },
  {
    name: "hyperfibre",
    path: "/hyperfibre",
    meta: hyperfibrehYnB3HzuEfMeta || {},
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/hyperfibre.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index7QULYiK9AVMeta || {},
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/index.vue")
  },
  {
    name: "internet-speed-test",
    path: "/internet-speed-test",
    meta: internet_45speed_45testYt4JbH04a0Meta || {},
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/internet-speed-test.vue")
  },
  {
    name: "join-now",
    path: "/join-now",
    meta: join_45now208fEVKVquMeta || {},
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/join-now.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: logina9z98nLKACMeta || {},
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/login.vue")
  },
  {
    name: "logout",
    path: "/logout",
    meta: logoutARfpX0Wm29Meta || {},
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/logout.vue")
  },
  {
    name: "mobile-compare",
    path: "/mobile-compare",
    meta: mobile_45compareg6R4L1nQNxMeta || {},
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/mobile-compare.vue")
  },
  {
    name: "mobile-friends-and-family",
    path: "/mobile-friends-and-family",
    meta: mobile_45friends_45and_45family1wMlocmNt9Meta || {},
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/mobile-friends-and-family.vue")
  },
  {
    name: "mobile",
    path: "/mobile",
    meta: indexsODkf854b2Meta || {},
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/mobile/index.vue")
  },
  {
    name: registerBhA7sDaS2OMeta?.name,
    path: "/mobile/register",
    meta: registerBhA7sDaS2OMeta || {},
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/mobile/register.vue"),
    children: [
  {
    name: "mobile-register-account",
    path: "account",
    meta: accountjPelNWerEvMeta || {},
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/mobile/register/account.vue")
  },
  {
    name: "mobile-register-connection-setup",
    path: "connection-setup",
    meta: connection_45setupQQBKppM3iPMeta || {},
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/mobile/register/connection-setup.vue")
  },
  {
    name: "mobile-register",
    path: "",
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/mobile/register/index.vue")
  },
  {
    name: "mobile-register-order-summary",
    path: "order-summary",
    meta: order_45summaryY3ODCoA0iWMeta || {},
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/mobile/register/order-summary.vue")
  },
  {
    name: "mobile-register-select-plan",
    path: "select-plan",
    meta: select_45plancpqFIDIfmdMeta || {},
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/mobile/register/select-plan.vue")
  },
  {
    name: "mobile-register-success",
    path: "success",
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/mobile/register/success.vue")
  }
]
  },
  {
    name: "our-plans",
    path: "/our-plans",
    meta: our_45plansd3X0BwBshXMeta || {},
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/our-plans.vue")
  },
  {
    name: "plans",
    path: "/plans",
    meta: plansCzbA89XpovMeta || {},
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/plans.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyFK1Y06mJ8gMeta || {},
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/privacy.vue")
  },
  {
    name: "promotions",
    path: "/promotions",
    meta: promotions3O9mjtZu6bMeta || {},
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/promotions.vue")
  },
  {
    name: "refer-a-friend",
    path: "/refer-a-friend",
    meta: refer_45a_45friendJ0wqn2jNVhMeta || {},
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/refer-a-friend.vue")
  },
  {
    name: registerve287vCXZMMeta?.name,
    path: "/register",
    meta: registerve287vCXZMMeta || {},
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/register.vue"),
    children: [
  {
    name: "register-1",
    path: "1",
    meta: _1dTwqkoOywoMeta || {},
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/register/1.vue")
  },
  {
    name: "register-2",
    path: "2",
    meta: _2uFaKkAp6OmMeta || {},
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/register/2.vue")
  },
  {
    name: "register-3",
    path: "3",
    meta: _3AX3cNiAuvUMeta || {},
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/register/3.vue")
  },
  {
    name: "register-4",
    path: "4",
    meta: _4lmrKynrEkcMeta || {},
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/register/4.vue")
  },
  {
    name: "register-5",
    path: "5",
    meta: _5lVyFMvr5cDMeta || {},
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/register/5.vue")
  },
  {
    name: "register",
    path: "",
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/register/index.vue")
  },
  {
    name: "register-success",
    path: "success",
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/register/success.vue")
  }
]
  },
  {
    name: "simply-broadband",
    path: "/simply-broadband",
    meta: simply_45broadbandF2pFkpAu7pMeta || {},
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/simply-broadband.vue")
  },
  {
    name: "studentcard-exclusive-offer",
    path: "/studentcard-exclusive-offer",
    meta: studentcard_45exclusive_45offer41GNsfHRerMeta || {},
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/studentcard-exclusive-offer.vue")
  },
  {
    name: "sustainability",
    path: "/sustainability",
    meta: sustainabilityusEo9l6udhMeta || {},
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/sustainability.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termscQxhxEDPsrMeta || {},
    component: () => import("/codebuild/output/src2177761829/src/zeronet-web/pages/terms.vue")
  }
]