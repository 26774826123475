import { useScriptMetaPixel, useScript, defineNuxtPlugin } from '#imports'

export default defineNuxtPlugin({
  name: "scripts:init",
  env: { islands: false },
  parallel: true,
  setup() {
    const metaPixel = useScriptMetaPixel({"id":"6824791350938067"})
    return { provide: { $scripts: { metaPixel } } }
  }
})