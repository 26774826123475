<template>
  <header class="app-header" :class="{ 'is-nav-open': isNavOpen, 'is-register': isRegistrationFunnel }">
    <div class="app-header__inner container">
      <main-logo class="app-header__logo" />
      <template v-if="!isRegistrationFunnel">
        <app-nav ref="nav" class="app-header__nav" @close-modal="closeNav" />

        <div class="app-header__ctas">
          <ui-button class="app-header__join-now" tag="app-link" :modifier="['active', 'small']" to="/join-now">Join Now</ui-button>
          <button type="button" class="app-header__toggle" aria-label="Toggle Navigation" @click="toggleNav">
            <svg class="app-header__toggle-icon" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g class="app-header__toggle-lines">
                <path d="M3.00098 15H27.001" stroke="currentColor" stroke-width="4" stroke-linecap="round" />
                <path d="M3.00098 27H27.001" stroke="currentColor" stroke-width="4" stroke-linecap="round" />
                <path d="M3.00098 3H27.001" stroke="currentColor" stroke-width="4" stroke-linecap="round" />
              </g>
              <g class="app-header__toggle-close">
                <line x1="2.82842" y1="3.07009" x2="26.9961" y2="27.1716" stroke="currentColor" stroke-width="4" stroke-linecap="round" />
                <line x1="26.9814" y1="2.88385" x2="2.84683" y2="27.0185" stroke="currentColor" stroke-width="4" stroke-linecap="round" />
              </g>
            </svg>
          </button>
        </div>
      </template>
    </div>
  </header>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import AppNav from '@/components/global/layout/AppNav.vue';
import MainLogo from '@/components/global/elements/MainLogo.vue';
import { useResponsiveBreakpoints } from '@/composables/breakpoints';
import { useRoute } from 'vue-router';
import { scrollLock, scrollUnlock } from '~/utils/scroll';
import UiButton from '~/components/global/ui/UiButton.vue';

const nav = ref<typeof AppNav | null>(null);

const { isRegistrationFunnel } = useRegistration();

const isNavOpen = ref(false);
const { isDesktop } = useResponsiveBreakpoints();
const route = useRoute();

const toggleNav = () => {
  isNavOpen.value = !isNavOpen.value;
};

const closeNav = () => {
  isNavOpen.value = false;
};

watch(isDesktop, (value) => {
  if (value) {
    closeNav();
  }
});

watch(route, () => closeNav());

watch(isNavOpen, (value) => {
  if (!nav.value) {
    return;
  }
  if (value) {
    scrollLock(nav.value.$el);
  } else {
    scrollUnlock(nav.value.$el);
  }
});
</script>

<style lang="scss" scoped>
.app-header {
  color: var(--primary);
  background-color: var(--white);
  position: relative;
  z-index: map-get($z-index, header);

  &.is-nav-open {
    //background-color: var(--primary);
    z-index: map-get($z-index, popup);
  }

  &__inner {
    height: var(--header-height);
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
  }

  &__toggle {
    width: rem(20);
    height: rem(20);

    &-lines {
      .is-nav-open & {
        opacity: 0;
      }
    }

    &-close {
      opacity: 0;
      color: var(--primary);

      .is-nav-open & {
        opacity: 1;
      }
    }
  }

  &__ctas {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1em;
  }

  &__join-now {
    @include tiny {
      display: none;
    }
  }
}
</style>
