<template>
  <div id="app" class="app">
    <nuxt-layout>
      <nuxt-page />
    </nuxt-layout>
  </div>
</template>

<script lang="ts" setup>
import { computed, useHead, useRoute } from '#imports';
import { useTracking } from '~/composables/tracking';
import { generatePageMeta } from '~/lib/meta';

const route = useRoute();

const { trackPageView } = useTracking();
watch(
  route,
  () => {
    trackPageView();
  },
  {
    immediate: true,
  }
);

const meta = computed(() =>
  generatePageMeta({
    route,
  })
);
useHead(meta);
</script>
